export default class Auth {
    widgetInstance = null;
    endpointUrl = null;
    setEndpointUrl(endpoint) {
        this.endpointUrl = endpoint;
    }
    init() {
        return new Promise((resolve, reject) =>  {
            window.initedWidget.then(() => {
                window.isLogined = false;
                window.TxGlobalAuth.subscribeJWT((response) => {
                    console.log('response', response);
                    if (response && 'token' in response) {
                        window.isLogined = true;
                        this.sendTokenToServer(response.token);
                        this.manageReloadCounter('reset');
                        window.dispatchEvent(new Event('auth-widget:user-auth'));
                    }
                    if (response === null) {
                        window.dispatchEvent(new Event('auth-widget:user-not-auth'));
                        this.doLogout();
                    }
                });

                window.TxGlobalAuth.subscribeGlobal('logout', () => {
                    this.doLogout();
                });

                window.TxGlobalAuth.subscribeGlobal('authenticationChange', (isAuthenticated) => {
                    window.isLogined = isAuthenticated;
                });

                if (window.isLogined) {
                    window.TxGlobalAuth.requireUserIdentifiers({ requirements: { email: true, phone: true } })
                        .then(() => console.log('У пользователя есть емайл и телефон'))
                        .catch((e) => console.error('Пользователь не заполнил все запрошенные данные, или произошла ошибка:', e));
                }


                this.widgetInstance = window.TxGlobalAuth;
            }).then(() => {
                resolve(this.widgetInstance);
            }).catch((e) => {
                reject(e);
            });
        })
    }

    isLoggedIn()
    {
        return window.TxGlobalAuth.isLoggedIn();
    }

    doLogout() {
        if (!this.manageReloadCounter('check')) {
            return;
        }

        this.manageReloadCounter('increment');
        // Если мы НЕ авторизованы, то есть != 1 -> выходим и ничего не делаем.
        if (document.querySelector("html").dataset.authenticated !== "1") {
            return;
        }
        window.isLogined = false;
        fetch('/logout', {
            method: 'POST',
        }).then((response) => {
            this.manageReloadCounter('reset');
            window.location.reload();
        });
    }

    getQueryParamValue(paramName) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(paramName); // Возвращает значение параметра или null, если параметр не существует
    }

    sendTokenToServer(token) {
        console.log('token send to server', token);
        let formData = new FormData();
        formData.append('token', token);
        formData.append('via', 'txauth');
        formData.append('backUrl', this.getQueryParamValue('backUrl') ?? window.location.href);
        fetch(this.endpointUrl, {
            method: 'POST',
            body: formData
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.is_register) {
                if (typeof AppMetrika !== 'undefined' && AppMetrika) {
                    AppMetrika.studentSuccessfullyRegistered();
                }
            }
            if (data.redirect && data.just_logged_in) {
                if (typeof AppMetrika !== 'undefined' && AppMetrika) {
                    AppMetrika.studentSuccessfullyLogged();
                }
                this.manageReloadCounter('reset');
                window.location.href = data.redirect;
            } else if (data.redirect === 'reload') {
                if (!this.manageReloadCounter('check')) {
                    return;
                }

                this.manageReloadCounter('increment');
                window.location.reload();
            } else if (data.status === 'error' && window.TxGlobalAuth.isLoggedIn()) {
                console.log('Error with authentication');
                //window.dispatchEvent(new Event('auth-widget:user-not-auth'));
                //window.TxGlobalAuth.logout();
            }
        });
    }


    manageReloadCounter(action) {
        const maxReloads = 3;
        const reloadResetTime = 10 * 1000;
        let reloadCount = parseInt(window.localStorage.getItem('reloadCount')) || 0;
        let lastReloadTime = parseInt(window.localStorage.getItem('lastReloadTime')) || 0;

        if (action === 'check') {
            const currentTime = new Date().getTime();
            if (currentTime - lastReloadTime > reloadResetTime) {
                window.localStorage.setItem('reloadCount', '0');
                reloadCount = 0;
            }

            if (reloadCount >= maxReloads) {
                console.log('Превышено количество попыток перезагрузки, перезагрузка не будет выполнена');
                return false;
            }

            return true;
        } else if (action === 'increment') {
            window.localStorage.setItem('reloadCount', reloadCount + 1);
            window.localStorage.setItem('lastReloadTime', new Date().getTime().toString());
        } else if (action === 'reset') {
            window.localStorage.removeItem('reloadCount');
            window.localStorage.removeItem('lastReloadTime');
        }
    }

    logoutOnServer() {
        console.log('logout on server');
        this.doLogout();
    }

    showLogin() {
        window.TxGlobalAuth.authenticate().then(() => {
            this.showUserDataEmailAndPhone();
        });
    }

    showRegistration() {
        window.TxGlobalAuth.authenticate({ start: 'registration' }).then(() => {
            this.showUserDataEmailAndPhone();
        });
    }

    showLogout() {
        window.TxGlobalAuth.logout();
    }

    showUserDataEmail(){
        window.TxGlobalAuth.requireUserIdentifiers({ requirements: { email: true } });
    }

    showUserDataPhone(){
        window.TxGlobalAuth.requireUserIdentifiers({ requirements: { phone: true } });
    }

    showUserDataEmailAndPhone(){
        window.TxGlobalAuth.requireUserIdentifiers({ requirements: { email: true, phone: true } });
    }

    showSettings(){
        window.TxGlobalAuth.showSettings();
    }

    openUrlWithAuth(url, target) {
        window.TxGlobalAuth.openApp({ url: url, target: target ?? "_blank" });
    }

    toggleUserMenu(container) {
        window.GlobalUserMenu.toggle(container);
    }
}
